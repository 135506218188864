<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-overlay :show="loading">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year" vid="fiscal_year_id"  rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="data.fiscal_year_id"
                                  :options="fiscalList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @change="getApprovedScheduleDate"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Season" vid="seasons_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="seasons_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('cotton_ginner_grower.season') }}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="data.seasons_id"
                                  :options="seasonList"
                                  id="seasons_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @change="getApprovedScheduleDate"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                          <ValidationProvider name="Region">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="region_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('cotton_ginner_grower.region')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                  id="region_id"
                                  v-model="data.region_id"
                                  :options="regionList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="isOfficeUser"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                          <ValidationProvider name="Zone">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="zone_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('cotton_ginner_grower.zone')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                  id="zone_id"
                                  v-model="data.zone_id"
                                  :options="zoneList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="isOfficeUser"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Unit" vid="unit_id"  rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="unit_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('cotton_ginner_grower.unit')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="data.unit_id"
                                  :options="unitList"
                                  id="unit_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="isOfficeUser"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                          <ValidationProvider>
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="district_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('org_pro_district.district') }}
                                  </template>
                                  <!-- <b-form-input
                                      disabled
                                      v-if="($i18n.locale === 'en')"
                                      id="district_id"
                                      v-model="data.district_name"
                                  ></b-form-input>
                                  <b-form-input
                                      disabled
                                      v-if="($i18n.locale === 'bn')"
                                      id="district_id"
                                      v-model="data.district_name_bn"
                                  ></b-form-input> -->
                                  <b-form-select
                                    plain
                                    v-model="data.district_id"
                                    :options="districtList"
                                    id="district_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option value="0">
                                            {{ $t('globalTrans.select') }}
                                        </b-form-select-option>
                                    </template>
                                  </b-form-select>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                        <ValidationProvider>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="upazilla_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.upazila') }}
                                </template>
                                <!-- <b-form-input
                                    disabled
                                    v-if="($i18n.locale === 'en')"
                                    id="upazilla_id"
                                    v-model="data.upazila_name"
                                ></b-form-input>
                                <b-form-input
                                    disabled
                                    v-if="($i18n.locale === 'bn')"
                                    id="upazilla_id"
                                    v-model="data.upazila_name_bn"
                                ></b-form-input> -->
                                <b-form-select
                                  plain
                                  v-model="data.upazila_id"
                                  :options="upazilaList"
                                  id="upazila_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option value="0">
                                          {{ $t('globalTrans.select') }}
                                      </b-form-select-option>
                                  </template>
                                  </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Grower" vid="applicant_id"  rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="applicant_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('cotton_ginner_grower.grower') }}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="data.applicant_id"
                                  :options="growerList"
                                  id="applicant_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option value="0">
                                    {{ growerLoading ? 'Loading..' : $t('globalTrans.select') }}
                                  </b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Ginner" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="ginner_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('cotton_ginner_grower.ginnerName') }}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="data.ginner_id"
                                  :options="ginnerList"
                                  id="ginner_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option value="0">
                                    {{ $t('globalTrans.select') }}
                                  </b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Approved Schedule Date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="approved_schedule_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('cotton_ginner_grower.approvedScheduleDate') }}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="data.approved_schedule_date"
                                  :options="dateList"
                                  id="approved_schedule_date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="''">
                                    {{ dateLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}
                                  </b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Hatt name" vid="hatt_id"  rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="hart_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('cotton_ginner_grower.hat_name')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="data.hatt_id"
                                  :options="hatList"
                                  id="hatt_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Cotton Variety" vid="cotton_variety_id"  rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="cotton_variety_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('cotton_config.cotton_variety')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="data.cotton_variety_id"
                                  :options="cottonVaritiesList"
                                  id="cotton_variety_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Cotton Name" vid="cotton_id"  rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="cotton_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('cotton_config.cotton_name')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="data.cotton_id"
                                  :options="cottonNameList"
                                  id="cotton_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Hatt Date" vid="hatt_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="hatt_date"
                            >
                              <template v-slot:label>
                                {{$t('cotton_ginner_grower.hattDate')}} <span class="text-danger">*</span>
                              </template>
                              <flat-pickr class="form-control"
                                          v-model="data.hatt_date"
                              ></flat-pickr>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col  lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Quantity" vid="quantity" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="quantity"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('cotton_ginner_grower.quantity')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  type="number"
                                  id="quantity"
                                  v-model="data.quantity"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              <div class="invalid-feedback d-block" v-if="stockQtyMessage">
                                {{ stockQtyMessage }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col  lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Price" vid="price" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="price"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('cotton_ginner_grower.price')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  type="number"
                                  id="quantity"
                                  v-model="data.price"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { sellEntryStore, growerList, approvedScheduleDateList, sellEntryStockQtyApi } from '../../api/routes'
import Common from '@/Utils/common'
import { getCottonMarketUserInfo } from '@/Utils/data-filtering-by-priviledge'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      this.getEditData(this.id)
    }
    // this.getGrowerList()
    this.data.hatt_date = this.currentDate
    if (this.isOfficeUser) {
      const authOfficeDetail = this.$store.state.Auth.authUser.office_detail
      const unit = this.$store.state.agriMarketing.commonObj.unitList.find(obj => obj.district_id === authOfficeDetail.district_id)
      if (unit !== undefined) {
        this.data.region_id = unit.region_id
        this.data.zone_id = unit.zone_id
        this.data.unit_id = unit.value
        this.data.district_id = unit.district_id
        this.data.upazilla_id = unit.upazilla_id
      }
    }
    this.setUserData()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      data: {
        id: '',
        org_id: 0,
        fiscal_year_id: 0,
        applicant_id: 0,
        seasons_id: 0,
        region_id: 0,
        zone_id: 0,
        unit_id: 0,
        district_id: 0,
        upazilla_id: 0,
        cotton_variety_id: 0,
        cotton_id: 0,
        quantity: '',
        hatt_date: null,
        price: '',
        district_name: '',
        district_name_bn: '',
        upazila_name: '',
        upazila_name_bn: '',
        zone_name: '',
        zone_name_bn: '',
        region_name: '',
        region_name_bn: '',
        unit_name: '',
        unit_name_bn: '',
        name: '',
        name_bn: '',
        address: '',
        address_bn: '',
        father_name: '',
        father_name_bn: '',
        hatt_id: 0,
        ginner_id: 0,
        approved_schedule_date: ''
      },
      cottonNameList: [],
      zoneList: [],
      unitList: [],
      growerList: [],
      upazilaList: [],
      loader: false,
      growerLoading: false,
      dateLoading: false,
      dateList: [],
      hatList: [],
      stockQtyMessage: ''
    }
  },
  watch: {
    'data.cotton_variety_id': function (newVal, oldVal) {
      this.cottonNameList = this.getCottonNameList(newVal)
    },
    'data.region_id': function (newVal, oldVal) {
      this.zoneList = this.getZoneList(newVal)
    },
    'data.zone_id': function (newVal, oldVal) {
      this.unitList = this.getUnitList(newVal)
    },
    'data.unit_id': async function (newVal, oldVal) {
      this.growerList = await this.getGrowerList(newVal)
      this.setDistrictUpazila(newVal)
    },
    'data.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'data.applicant_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getApplicantInfo(newVal)
      }
    },
    'data.cotton_id': function (newVal, oldVal) {
      if (this.data.fiscal_year_id && this.data.seasons_id && this.data.applicant_id && this.data.cotton_variety_id && this.data.cotton_id) {
        this.getCottonStockQty()
      }
    },
    cottonMarketUserInfo: function () {
        this.setUserData()
    }
  },
  computed: {
    cottonMarketUserInfo () {
        return getCottonMarketUserInfo()
    },
    orgList: function () {
      const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return tmpData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text }
        }
      })
    },
    fiscalList: function () {
      return Common.getFiscalYearList()
    },
    // hatList: function () {
    //   return this.$store.state.agriMarketing.commonObj.hatList.filter(item => item.status === 1)
    // },
    cottonVaritiesList: function () {
      return this.$store.state.agriMarketing.commonObj.cottonVaritiesList.filter(item => item.status === 1)
    },
    regionList: function () {
      //  return this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1)
       return this.cottonMarketUserInfo.regionList.filter(item => item.status === 1)
    },
    seasonList: function () {
       return this.$store.state.agriMarketing.commonObj.seasonList.filter(item => item.status === 1)
    },
    districtList () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    },
    ginnerList () {
      return this.$store.state.agriMarketing.commonObj.ginnerGrowerList.filter(item => item.type === 1 && item.status === 1)
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentDate () {
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()

      return yyyy + '-' + mm + '-' + dd
    },
    isOfficeUser () {
      // return !(this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.is_org_admin)
      return false
    }
  },
  methods: {
    async getGrowerList (unitId) {
      this.growerLoading = true
      let list = []
      const result = await RestApi.getData(agriMarketingServiceBaseUrl, growerList, { unit_id: unitId, type: 2 })
      if (result.success) {
          const data = result.data
          if (data.length > 0) {
              this.$store.dispatch('agriMarketingCottonGinnerGrower/setGrowerDropdownList', data)
              list = data.map(el => {
                  el.text = this.$i18n.locale === 'bn' ? el.name_bn : el.name
                  el.value = el.applicant_id
                  return el
              })
          }
          this.growerLoading = false
      } else {
          this.growerLoading = false
      }
      return list
    },
    getCottonNameList (cottonVarietyId = null) {
      const cottonNameList = this.$store.state.agriMarketing.commonObj.cottonNameList.filter(item => (item.status === 1) && (item.cotton_variety_id === cottonVarietyId))
      if (cottonVarietyId) {
        return cottonNameList.filter(cotton => cotton.cotton_variety_id === cottonVarietyId)
      }
      return cottonNameList
    },
    getZoneList (regionId = null) {
      // const zoneList = this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.status === 1)
      const zoneList = this.cottonMarketUserInfo.zoneList.filter(item => item.status === 1)
      if (regionId) {
        return zoneList.filter(region => region.region_id === regionId)
      }
      return zoneList
    },
    getUnitList (ZoneId = null) {
      // const unitList = this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1)
      const unitList = this.cottonMarketUserInfo.unitList.filter(item => item.status === 1)
      if (ZoneId) {
        return unitList.filter(unit => unit.zone_id === ZoneId)
      }
      return unitList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.cottonMarketUserInfo.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getHatList (districtId) {
        const hatList = this.$store.state.agriMarketing.commonObj.hatList.filter(item => item.district_id === parseInt(districtId))
        return hatList
    },
    async getApprovedScheduleDate () {
      if (this.data.fiscal_year_id && this.data.seasons_id) {
        this.dateLoading = true
        const result = await RestApi.postData(agriMarketingServiceBaseUrl, approvedScheduleDateList, this.data)
        if (result.success) {
          this.dateList = result.data
        } else {
          this.dateList = []
        }
        this.dateLoading = false
      }
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.postData(agriMarketingServiceBaseUrl, sellEntryStore, this.data)
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$toast.error({
          title: 'Success',
          message: result.message,
          color: '#D6E09B'
        })
      }
    },
    getApplicantInfo (applicantId) {
      // const grower = this.$store.state.agriMarketingCottonGinnerGrower.growerDropdownList.find(item => item.value === applicantId)
      const grower = this.growerList.find(item => item.value === applicantId)
      const district = this.$store.state.commonObj.districtList.find(dist => dist.value === grower.district_id)
      const upazilla = this.$store.state.commonObj.upazilaList.find(upz => upz.value === grower.upazilla_id)

      Object.assign(this.data, grower,
        { district_name: district.text_en, district_name_bn: district.text_bn },
        { upazila_name: upazilla.text_en, upazila_name_bn: upazilla.text_bn }
      )
      },
      getRelatinalData (data) {
        const districtList = this.$store.state.commonObj.districtList
        const upazilaList = this.$store.state.commonObj.upazilaList

        const listData = data.map(item => {
            const districtObject = districtList.find(district => district.value === item.district_id)
            const upazilaObject = upazilaList.find(upazila => upazila.value === item.upazilla_id)

            const districtData = { district_name: districtObject.text, district_name_bn: districtObject.text_bn }
            const upazilaData = { upazila_name: upazilaObject.text, upazila_name_bn: upazilaObject.text_bn }

            return Object.assign({}, item, districtData, upazilaData)
        })
        return listData
      },
      setUserData () {
          if (this.cottonMarketUserInfo.regionList.length === 1) {
              this.data.region_id = this.cottonMarketUserInfo.regionList[0].value
          }
          if (this.cottonMarketUserInfo.zoneList.length === 1) {
              this.data.zone_id = this.cottonMarketUserInfo.zoneList[0].value
          }
          if (this.cottonMarketUserInfo.unitList.length === 1) {
              this.data.unit_id = this.cottonMarketUserInfo.unitList[0].value
          }
      },
      setDistrictUpazila (id) {
        const obj = this.cottonMarketUserInfo.unitList.find(item => item.value === parseInt(id))
        this.data = Object.assign({}, this.data, {
          district_id: obj.district_id,
          upazila_id: obj.upazilla_id
        })
        this.hatList = this.getHatList(obj.district_id)
      },
      async getCottonStockQty () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        this.stockQtyMessage = ''
        const result = await RestApi.getData(agriMarketingServiceBaseUrl, sellEntryStockQtyApi, this.data)
        if (result.success) {
          this.stockQtyMessage = 'Max quantity will be ' + result.data.quantity
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }
  }
}
</script>
